import { getDefaultFields } from "../common/common.fields";

export const IMAGE_ELEMENT_FORM = {
  sectionName: "Image Section",
  elements: [
    {
      inputType: "list",
      label: "Image info",
      hasStyleProps: true,
      styleElementId: 1,
      stylesProps: {},
      updateTo: "images.list",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "Image Link",
            defaultValue:
              "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
            updateTo: "images.list[i].url",
            extraPropUpdateTo: "images.list[i]",
            extraPropValues: {
              url:
                "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
              styleProps: {
                "justify-content": "center",
              },
              innerDivStyle: {
                width: "100%",
              },
            },
          },
          {
            inputType: "text",
            label: "Alt",
            defaultValue: "",
            updateTo: "images.list[i].alt",
            hasStyleProps: false,
          }, 
        ],
        hasStyleProps: true,
        styleElementId: 2,
        stylesProps: {},
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Image section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Image section",
      elements: [
        {
          inputType: "columns",
          label: "Columns",
          updateTo: "imagesPerRow",
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "images.styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Image section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "images.list[i].styleProps",
          fields: ["justify-align"],
        }),
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "images.list[i].innerDivStyle",
          fields: ["width-percent"],
        }),
      ],
    },
  ],
};
