import { twdClass } from "../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../constants/var.constants";
import FaIcons from "../../common/FaIcons";
import { Button } from "../../ui/com_ui";
import PageSectionLayout from "./PageSectionLayout";
import {
  UPDATE_PROJECT_PAGE,
  SELECT_PAGE_ID,
  CREATE_NEW_PAGE,
} from "../../../constants/action.constants";
import { useDispatch, useSelector } from "react-redux";
import { getPageJSONData } from "../../../api/services";
import { toast } from "react-toastify";

const PG_1 = twdClass("flex w-full");
const PG_2 = twdClass("w-52 mr-6 mt-4");
const PG_4 = twdClass(
  `${BTN_CLS} bg-indigo-600 text-white hover:bg-indigo-800 w-full font-bold`
);

const PagesLayout = () => {
  const dispatch = useDispatch();
  const {
    selectedPageId,
    projectData: { project_id, pages },
  } = useSelector((state) => state.studioDetails);

  const fetchPageInfo = async (page_id) => {
    const json = await getPageJSONData({
      project_id,
      page_id,
    });
    if (json.success) {   
      let hasDraft=false        
      /*
      let forDraft = true   
      let orig_blocks=[]
      
      if(forDraft==true){        
        hasDraft=true       
        if(json.data.blocks) {
          orig_blocks=json.data.blocks
        }
      }
      dispatch({ type: UPDATE_PROJECT_PAGE, data:{...json.data, hasDraft, orig_blocks}});
      */
      dispatch({ type: UPDATE_PROJECT_PAGE, data:{...json.data, hasDraft}});
    } else {
      toast.error("Error while fetching page info");
    }
  };

  return (
    <div className={`pages-layout ${PG_1}`}>
      <div className={PG_2}>
        <div>
          <Button
            type="default"
            color="black"
            className={"mr-6"}
            onClick={() => {
              const pageName =
                pages.length > 0 ? `New Page - ${pages.length + 1}` : "Home";
              const pageSlug =
                pages.length > 0 ? `/new-page-${pages.length + 1}` : "";
              //console.log("add Page -INIT- for Create_new_page", pageName, pageSlug)
              dispatch({
                type: CREATE_NEW_PAGE,
                data: { pageName, pageSlug },
              });
            }}
          >
            <FaIcons icon="layergroup" className={twdClass("mr-4")} />
            Add Page
          </Button>
        </div>
        <div className={`pages-list ${twdClass("mt-3")}`}>
          {pages.map((item) => (
            <div
              className={twdClass(
                `mb-1 px-4 py-2 text-sm rounded-tl-2xl rounded-bl-2xl cursor-pointer hover:bg-white flex items-center ${
                  selectedPageId === item.page_id ? "bg-white" : ""
                }`
              )}
              onClick={() => {
                fetchPageInfo(item.page_id);
                dispatch({ type: SELECT_PAGE_ID, id: item.page_id });
              }}
            >
              <img
                width="18px"
                src="/assets/images/file-04.svg"
                className={twdClass("mr-2")}
                alt=""
              />{" "}
              {item.page_name}
            </div>
          ))}
        </div>
      </div>
      <PageSectionLayout />
    </div>
  );
};

export default PagesLayout;
