import "./index";
import axios from "axios";
import {
  DEBUG,
  SAVE_PROJECT,
  DUPLICATE_PROJECT,
  UPDATE_PROJECT,
  CREATE_USER,
  CREATE_PROJECT,
  PROJECTS_LIST,
  PROJECT_OPTS,
  PROJECT_READ,
  GOOGLE_SHEET_INFO,
  GOOGLE_SHEET_COLUMNS,
  ADD_CONNECTION,
  DELETE_CONNECTION,
  CONNECTIONS_LIST,
  DELETE_PAGE,
  GET_ALL_PAGES,
  PROJECT_SITE_SETTINGS,
  CHECK_AVAILBILITY,
  CHECK_GOOGLE_SHEET_ACCESS,
  LOGIN_USER,
  USER_PLAN,
  ADD_NEW_BLOCK,
  SUPABASE_CONNECTION,
  CREATE_TEMPLATES,
  GET_PROJECT_TEMPLATE_IDS,
  CREATE_PROJECT_FROM_TEMPLATE,
  GET_USER_CHECK,
  UPDATE_ONBOARDING
} from "./endpoint.urlconstants";

export const debug = (data) => {
  return axios.post(DEBUG, data);
};

export const getLoginUserInfo = () => {
  return axios.get(LOGIN_USER);
};

export const createUser = (data) => {
  return axios.post(CREATE_USER, data);
};

export const createProject = (data) => {
  return axios.post(CREATE_PROJECT, data);
};

export const saveProject = (data) => {
  return axios.post(SAVE_PROJECT, data);
};

export const updateProject = (data) => {
  return axios.post(UPDATE_PROJECT, data);
};

export const getProjectList = () => {
  return axios.get(PROJECTS_LIST);
};

export const deleteProject = (id) => {
  return axios.delete(`${PROJECT_OPTS}/${id}`);
};
export const duplicateProject = (data) => {
  return axios.post(DUPLICATE_PROJECT, data);
};

export const getProjectInfo = (id) => {
  return axios.get(`${PROJECT_OPTS}/${id}`);
};

export const getProjectJSONData = (id) => {
  return axios.get(`${PROJECT_READ}/${id}`);
};

export const getPageJSONData = (params) => {
  return axios.post(`${PROJECT_READ}`, params);
};

export const getPagesList = (id) => {
  return axios.get(`${GET_ALL_PAGES}/${id}`);
};

export const getSheetInfo = (id) => {
  return axios.get(`${GOOGLE_SHEET_INFO}/${id}`);
};

export const getSheetColumns = (data) => {
  return axios.post(GOOGLE_SHEET_COLUMNS, data);
};

export const addNewConnection = (data) => {
  return axios.post(ADD_CONNECTION, data);
};

export const deleteConnection = (id) => {
  return axios.delete(`${DELETE_CONNECTION}/${id}`);
};

export const loadConnections = (id) => {
  return axios.get(`${CONNECTIONS_LIST}/${id}`);
};

export const deletePageById = (id) => {
  return axios.delete(`${DELETE_PAGE}/${id}`);
};

export const getSiteSettings = (id) => {
  return axios.get(`${PROJECT_SITE_SETTINGS}/${id}`);
};

export const saveSiteSettings = (id, settings) => {
  return axios.post(`${PROJECT_SITE_SETTINGS}/${id}`, settings);
};

export const checkDomainAvailbity = (domain) => {
  return axios.get(`${CHECK_AVAILBILITY}`, {
    params: {
      ...domain,
    },
  });
};

export const checkSheetAccess = (info) => {
  return axios.post(`${CHECK_GOOGLE_SHEET_ACCESS}`, info);
};

export const updatePlanInfo = (data) => {
  return axios.post(USER_PLAN, data);
};

export const copyPastNewBlock = (info) => {
  return axios.post(ADD_NEW_BLOCK, info);
};

export const fetchSupabaseColumns = (info) => {
  return axios.post(SUPABASE_CONNECTION, info);
};

export const createProjectWithTemlate = (data) => {
  return axios.post(CREATE_TEMPLATES, data);
};
export const createProjectFromTemplate = (data) => {
  return axios.post(CREATE_PROJECT_FROM_TEMPLATE, data);
};
export const getProjectTemlateIds = (data) => {
  return axios.post(GET_PROJECT_TEMPLATE_IDS, data);
};
export const getUserCheck = (data) => {
  return axios.get(GET_USER_CHECK);
};
export const updateOnboarding = (data) => {
  return axios.post(UPDATE_ONBOARDING, data);
};





