import { twdClass } from "../../../constants/tailwind.constants";
import { Button} from "../../ui/com_ui";
import { HelpMenu, LogoMenu } from "../../common/ui";
import FaIcons from "../../common/FaIcons";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  BTN_NORMAL,
  SELECT_CLS,
  BTN_CLS,
} from "../../../constants/var.constants";
import {
  saveProject,
  getPageJSONData,
  getProjectInfo,
} from "../../../api/services";
import { find } from "lodash";
import {
  UPDATE_PROJECT_PAGE,
  UPDATE_PROJECT_INFO,
  TOGGEL_CONFIRM_POPUP,
  SELECT_PAGE_ID,
  TOGGEL_POPUP,
  UPDATE_PREVIEW_TYPE,
  RESET_AUTOSAVE,
  RESET_POPUP,
  UPDATE_APP_PREF,
} from "../../../constants/action.constants";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SectionsPanel from "../leftpanel/SectionsPanel";
import CreatePagePanel from "./CreatePagePanel";

const PG_4 = twdClass(
  `${BTN_CLS} w-36 bg-indigo-600 text-white hover:bg-indigo-800 font-bold`
);
const StudioHeader = () => {
  const {
    autoSave,
    pageInfo={},
    selectedPageId = "",
    projectData: { project_id, domain, pages },
  } = useSelector((state) => state.studioDetails);

  const {pref} = useSelector((state) => state.appdetails);  

  const {
    previewType
  } = useSelector((state) => state.studioView);

  const {
    settings,
    hasDraft=false
  } = pageInfo;

  //console.log("pageInfo", pageInfo)
  const dispatch = useDispatch();

  const saveProjectInfo = (data=false, publish=false) => {   
    if(!publish) {
      //console.log("save", pageInfo)
      data=pageInfo
      data.hasDraft=true     
    }
    //console.log("save", pageInfo)
    saveProject({ id: project_id, pageInfo: data!=false ? data : pageInfo}).then(async (resp) => {
      //console.log("pageInfo-", pageInfo)
     
      if (resp.success) {
        toast.success("Page successfully saved");
        fetchPageData({ project_id, page_id: resp.data[0].page_id});
        const { data } = await getProjectInfo(project_id);
        dispatch({ type: UPDATE_PROJECT_INFO, data });
      }
    });
  };
  const publishPage = () => {
    if(pageInfo.hasDraft==true){      
      //pageInfo.orig_blocks=pageInfo.blocks
      //pageInfo.blocks.push(pageInfo.blocks[0])
      pageInfo.hasDraft = false      
      pageInfo.backupSaved = false      
    }
    dispatch({ type: UPDATE_PROJECT_PAGE, data: {...pageInfo} });
    saveProjectInfo(pageInfo, true);
  };

  const createPagePanel = () => {
    //console.log("create Page -INIT- for Create_new_page")
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: "New Page",
        component: <CreatePagePanel fetchPageData={fetchPageData} />,
      },
    });
  };

  

  

  const fetchPageInfo = async (e) => {
    const value = e.target.value;
    const page = find(pages, ["page_id", +value]);
    
    fetchPageData({ project_id, page_id: page.page_id, forDraft:true });
    dispatch({ type: SELECT_PAGE_ID, id: page.page_id });
  };

  const fetchPageData = async ({ project_id, page_id, forDraft=false}) => {
    const json = await getPageJSONData({
      project_id,
      page_id,
    });
    if (json.success) { 
      let new_data={} 
     
      if(forDraft==true){  
        if(json.data.blocks) {
          new_data.orig_blocks=json.data.blocks
        }        
      } 
      dispatch({ type: UPDATE_PROJECT_PAGE, data:{...json.data, ...new_data}});
    } else {
      toast.error("Error while fetching page info");
    }
  };

  const appendClass = (item) => {
    dispatch({ type: UPDATE_PREVIEW_TYPE, data: item });
  };

  useEffect(() => {
    if(pref.autoSave && autoSave.newActions > 200){
      dispatch({
        type: TOGGEL_CONFIRM_POPUP,
        data: {
          showConfirmPopup: true,
          confirmLabel: "Do you want to save this page?",
          selectProject: '12',
          action:(<button className={twdClass(BTN_CLS+' mr-8')} onClick={()=>{dispatch({ type: UPDATE_APP_PREF, data: {autoSave:false} }); dispatch({ type: RESET_POPUP, data: {} });}}>Prevent this dialogs</button>),
          cancelCallBack: (info) =>  { console.log("cancel", info);  dispatch({ type: RESET_AUTOSAVE, data: {} });},
          confrimCallBack:  (info) =>  { console.log(info); dispatch({ type: RESET_POPUP, data: {} }); saveProjectInfo();},
        },
      });
      //saveProjectInfo()
    }
    //console.log("autoSave.newActions", autoSave.newActions)
  }, [autoSave.newActions]);

  return (
    <div
      className={twdClass(
        "p-3 bg-white flex justify-between items-center border-b border-gray-200 fixed w-full z-10"
      )}
    >
      <div className={twdClass("flex justify-between items-center")}>
        {/*<Link to="/" className={twdClass("flex justify-between items-center")}>
          <FaIcons icon="globe" className={twdClass("text-2xl")} />
          <span className={twdClass("ml-2 text-xl font-bold")}>Flezr</span>
        </Link>*/}
        <div className={twdClass("flex items-center bg-gray-100 rounded-full pl-2 pr-4 py-1 ")}>          
          <LogoMenu />
          <HelpMenu studio={true}/>
        </div>
        <div className={twdClass("flex items-center bg-gray-100 rounded-full pl-2 pr-2 py-2 ml-4 ")}>          
          <select
            className={`${twdClass("mr-2 rounded-full border border-gray-200 bg-white bg-opacity-50 py-1 px-4 pr-4")}`}
            onChange={fetchPageInfo}
            value={selectedPageId}
          >
            {pages.map((item, index) => (
              <option key={index} value={item.page_id}>{item.page_name}</option>
            ))}
          </select>
          <div>
            <Button
              type={'default'}
              color={'white'}
              size={'sm'}
              cType={'iconText'}
              iconImg={'file-plus-01.svg'}
              className={twdClass(``)}
              onClick={createPagePanel}
            >              
              Create Page
            </Button>
          </div>
        </div>
      </div>
      <div className={twdClass("flex items-center bg-gray-100 rounded-full pl-2 pr-2 py-2 ")}>          
        <Button onClick={() => appendClass("desktop")} 
           onoff={previewType=='desktop' ? 'on' : 'off'} 
          type={'icon'}      
          color={'white'}
          size={'sm'}         
          iconImg={'computer.svg'}         
          className={'mr-2'}        
        ></Button>
        <Button onClick={() => appendClass("tablet")} 
          onoff={previewType=='tablet' ? 'on' : 'off'}   
          type={'icon'}      
          color={'white'}
          size={'sm'}         
          iconImg={'Ipad.svg'}          
          className={'mr-2'}          
        ></Button>
        <Button onClick={() => appendClass("mobile")} 
          onoff={previewType=='mobile' ? 'on' : 'off'}  
          type={'icon'}      
          color={'white'}
          size={'sm'}         
          iconImg={'mobile.svg'}          
          className={twdClass(``)}         
        ></Button>
      </div>
      <div></div>
      <div className={twdClass("flex items-center")}>
        <div className={twdClass("flex items-center bg-gray-100 rounded-full pl-2 pr-2 py-2 mr-2")}>          
          <Button onClick={() => saveProjectInfo()}          
            type={'default'}  
            cType={'iconText'}        
            color={'white'}
            size={'sm'}         
            iconImg={'download-02.svg'}         
            className={'mr-2'}        
          >Save</Button>
          <Button  onClick={() => saveProjectInfo()}
            type={'default'} 
            cType={'iconText'}             
            color={'white'}
            size={'sm'}         
            iconImg={'arrow-refresh-06.svg'}          
            className={''}          
          >Data Sync</Button>
          {pageInfo.hasDraft && <Button onClick={() => publishPage()}          
            type={'default'}  
            cType={'iconText'}        
            color={'primary'}
            size={'sm'}         
            iconImg={'publish-sm.svg'}         
            className={'ml-2'}        
          >Publish</Button>}       
          
        </div>
        <Button onClick={() => window.open(`https://${domain}${settings.pageSlug ? settings.pageSlug: ''}?preview=1`)}
          type={'default'} 
          cType={'iconText'}             
          color={'black'}
          size={'md'}         
          iconImg={'link-external-w.svg'}          
          className={''}         
        >Preview</Button>
      </div>
      {/*<div>
        <span className={twdClass("mx-6")} onMouseEnter={() => {}}>
          <button onClick={() => appendClass("desktop")}>
            <FaIcons
              className={twdClass("text-gray-600 mr-2")}
              icon="desktop"
            />
          </button>
          <button onClick={() => appendClass("tablet")}>
            <FaIcons className={twdClass("text-gray-600 mr-2")} icon="tablet" />
          </button>
          <button onClick={() => appendClass("mobile")}>
            <FaIcons className={twdClass("text-gray-600 mr-2")} icon="mobile" />
          </button>
        </span>
        <button
          className={twdClass(BTN_NORMAL)}
          onClick={() => saveProjectInfo()}
        >
          <FaIcons icon="save" className={twdClass("mr-2")} />
          Save
        </button>
        <button
          className={twdClass(BTN_NORMAL)}
          onClick={() => saveProjectInfo()}
        >
          <FaIcons icon="sync" className={twdClass("mr-2")} />
          Data Sync
        </button>
        <button
          className={twdClass(BTN_NORMAL)}
          onClick={() => window.open("https://" + domain)}
        >
          <FaIcons icon="link" className={twdClass("mr-2")} />
          Preview
        </button>
      </div>*/}
    </div>
  );
};

export default StudioHeader;
