import { getDefaultFields } from "../common/common.fields";

export const VIDEO_ELEMENT_FORM = {
  sectionName: "Video Section",
  elements: [
    {
      inputType: "list",
      label: "Video info",
      hasStyleProps: true,
      styleElementId: 1,
      stylesProps: {},
      updateTo: "videos.list",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "Video Link",
            defaultValue: "https://www.youtube.com/watch?v=aqz-KE-bpKQ",
            updateTo: "videos.list[i].url",
            extraPropUpdateTo: "videos.list[i]",
            extraPropValues: {
              url: "https://www.youtube.com/watch?v=aqz-KE-bpKQ",
              styleProps: {
                "justify-content": "center",
              },
              videoHeight: {
                height: "400px",
              },
              innerDivStyle: {
                width: "75%",
              },
            },
          },
        ],
        hasStyleProps: true,
        styleElementId: 2,
        stylesProps: {},
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Video section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Video section",
      elements: [
        {
          inputType: "columns",
          label: "Columns",
          updateTo: "videosPerRow",
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videos.styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Video section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videos.list[i].styleProps",
          fields: ["justify-align"],
        }),
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videos.list[i].innerDivStyle",
          fields: ["width-percent"],
        }),
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videos.list[i].videoHeight",
          fields: ["height2px"],
        }),
      ],
    },
  ],
};
