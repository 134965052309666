import { twdClass } from "../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../constants/var.constants";
import FaIcons from "../../common/FaIcons";
import PagesLayoutHeader from "./PagesLayoutHeader";
import PagesSettingsPanel from "./PagesSettingsPanel";
import PageSiteSettingsPanel from "./PageSiteSettigsPanel";
import PageExternalJsPanel from "./PageExternalJsPanel";
import PageAdvancedPanel from "./PageAdvancedPanel";
import {
  CREATE_NEW_PAGE,
  UPDATE_PROJECT_PAGE,
  UPDATE_PROJECT_INFO,
  SELECT_PAGE_ID,
  UPADTE_PAGES_LIST,
  RESET_POPUP,
} from "../../../constants/action.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveProject, getProjectInfo, getPageJSONData, getPagesList } from "../../../api/services";
import { toast } from "react-toastify";
import { cloneDeep, set } from "lodash";

const PG_1 = twdClass("flex w-full");
const PG_2 = twdClass("w-48 mr-6 mt-4");
const PG_3 = twdClass("flex-1 bg-white -ml-8 -mr-4 -mb-1 rounded-xl ");
const PG_4 = twdClass(
  `${BTN_CLS} bg-indigo-600 text-white hover:bg-indigo-800 w-full font-bold`
);

const PageSectionLayout = () => {
  const dispatch = useDispatch();
  const {
    selectedPageId,
    pageInfo,
    projectData: { project_id, pages },
  } = useSelector((state) => state.studioDetails);

  const { settings, page_id } = pageInfo;

  const [activePage, setActivePage] = useState("page");

  const saveSettings = (action='', settingsData ={}) => {
    //console.log(action, pageInfo)    
    let pageInfoData = set(cloneDeep(pageInfo), 'settings', {...pageInfo.settings, ...settingsData});
    saveProject({ id: project_id, pageInfo:pageInfoData }).then(async (resp) => {
      if (resp.success) {
        toast.success("Page successfully updated");
        fetchPageInfo(resp.data[0].page_id);
        const { data } = await getProjectInfo(project_id);
        dispatch({ type: UPDATE_PROJECT_INFO, data });
        dispatch({ type: SELECT_PAGE_ID, id: resp.data[0].page_id });
        dispatch({ type: RESET_POPUP });
      }
    });    
  };


  const fetchPageInfo = async (page_id) => {
    const json = await getPageJSONData({
      project_id,
      page_id,
    });
    if (json.success) {
      dispatch({ type: UPDATE_PROJECT_PAGE, data: json.data });
    } else {
      toast.error("Error while fetching page info");
    }
  };

  const getAllPages = async (deletedPageId) => {
    const newlist = await getPagesList(project_id);
    if (newlist.success && +selectedPageId === +deletedPageId) {
      const id = newlist.data[0].page_id;
      dispatch({ type: SELECT_PAGE_ID, id });
      fetchPageInfo(id);
    }
    dispatch({ type: UPADTE_PAGES_LIST, data: newlist.data });
  };

  return (
    <div className={PG_3}>
        <PagesLayoutHeader
          settings={settings}
          page_id={page_id}
          getAllPages={getAllPages}
          activePage={activePage}
          setActivePage={setActivePage}
        />
        {activePage === 'page' &&  <PagesSettingsPanel {...{saveSettings}}/>}
        {activePage === 'site' &&  <PageSiteSettingsPanel {...{saveSettings}}/>}
        {activePage === 'extjs' &&  <PageExternalJsPanel {...{saveSettings}}/>}
        {activePage === 'advanced' &&  <PageAdvancedPanel {...{saveSettings}}/>}
      </div>
  );
};

export default PageSectionLayout;
