import { twdClass } from "../../constants/tailwind.constants";
import { isEmpty, find } from "lodash";
import { useState, useEffect } from "react";
import FaIcons from "../common/FaIcons";
import { INPUT_TEXT } from "../../constants/var.constants";
import { GoogleConnectionInfo } from "./GoogleConnectionInfo";
import { WhereClausePanel } from "./WhereClausePanel";
import { CONNECTIONS_METADATA } from "../studio/connections/connection.metadata";
import { useSelector, useDispatch } from "react-redux";

import { SupabaseConnectionInfo } from "./SupabaseConnectionInfo";
import { Button, BtnStyle, BtnBack, BtnNext} from "../ui/com_ui";
import { SettingsBtn} from "../ui/presets";
import  Collapsible from "../form/ui-elements/collapsible";

import {
  UPDATE_ADVANCED_ID,
} from "../../constants/action.constants";

import { DynamicCardsVs_play} from "../content/visual_steps";

const DC1 = twdClass(
  "flex items-center justify-between text-sm font-semibold cursor-pointer px-2"
);
const DC2 = twdClass(
  "text-sm font-semibold bg-primary-500 shadow-md rounded-full  cursor-pointer"
);
const DC3 = twdClass("flex items-center");
const DC4 = twdClass("bg-white border border-gray-100 hover:border-gray-200 mt-2 p-2 border rounded-2xl");
const DC5 = twdClass("flex items-center w-full mt-2 pt-2");
const DC6 = twdClass(
  "text-sm font-semibold bg-primary-500 shadow-md rounded-full mt-2 mb-2 cursor-pointer"
);

export const MainCardSection = ({
  activePanel,
  setActivePanel,
  currentBlock,
  updateElementValue,
  setColumnNames,
  columnNames,
}) => {
  const dispatch = useDispatch();
  const [showDS, setShowDS] = useState(true);
  const [showDC, setShowDC] = useState(true);

  const {
    projectData: { connections },
  } = useSelector((state) => state.studioDetails);

  const connection = find(connections, [
    "connection_id",
    currentBlock.configs.connection.connectionId,
  ]);
  const [connectionType, setConnectionType] = useState("");
  useEffect(() => {
    setConnectionType(connection?.connection_type);
  }, []);

  return (
    <div>
      {activePanel === "main" && (
        <div>
          <div className={twdClass("px-3 py-3 flex items-center")}>
            <div className={twdClass("flex items-center flex-grow mb-3")}>
              <div            
                className={twdClass("mr-4")}
              >
                <img src={'/assets/images/grid-01.svg'}/>
              </div>
              <div>
                <div className={twdClass("text-gray-900 text-lg font-semibold")}>
                  Cards Section
                </div>
                <div className={twdClass("text-xs")}>Section</div>
              </div>
            </div>
            <SettingsBtn  onClick={() => dispatch({ type: UPDATE_ADVANCED_ID, id: 0 })} className={"mr-2"}/>
            <div className={twdClass("mr-2")}>
              <DynamicCardsVs_play size="xs"/>
            </div>
            <BtnStyle   
              size={"xs"}           
              onClick={() => setActivePanel("styles")}
            >              
            </BtnStyle>
          </div>
          {!isEmpty(currentBlock) && (
            <div>
              <div className={twdClass("px-3")}>
                <div
                  className={DC2}
                  onClick={() => setActivePanel("headingtext")}
                >
                  <span className={DC3}>
                    <span className={twdClass("flex-grow bg-white border-2 border-primary-500 py-3 px-4 rounded-full")}>Configure Headings</span>
                    <span className={twdClass("py-2 px-3")}>
                      <BtnNext
                        size="sm"
                      /> 
                    </span>
                  </span>
                </div>
                <div className={DC4}>
                  <div className={DC1} onClick={() => setShowDS(!showDS)}>
                    <span>Configure Data Source</span>
                    <FaIcons
                      icon={`${showDS ? "angleUp" : "angleDown"}`}
                    ></FaIcons>
                  </div>
                  <div className={twdClass(`${showDS ? "px-2" : "hidden"}`)}>
                    <div className={DC5}>
                      <div className={twdClass("w-48 text-sm")}>
                        Data Source
                      </div>
                      <DataSourceSelect
                        connectionType={connectionType}
                        setConnectionType={setConnectionType}
                      />
                    </div>

                    {connectionType === "googleSheet" && (
                      <GoogleConnectionInfo
                        updateElementValue={updateElementValue}
                        setColumnNames={setColumnNames}
                        connectionType={connectionType}
                      />
                    )}
                    {connectionType === "supabase" && (
                      <SupabaseConnectionInfo
                        updateElementValue={updateElementValue}
                        setColumnNames={setColumnNames}
                        connectionType={connectionType}
                      />
                    )}
                  </div>
                </div>
                <div className={DC4}>
                  <div className={DC1} onClick={() => setShowDC(!showDC)}>
                    <span>Card Details</span>
                    <FaIcons
                      icon={`${showDC ? "angleUp" : "angleDown"}`}
                    ></FaIcons>
                  </div>
                  <div
                    className={twdClass(
                      `${showDC ? "" : "hidden"} mt-2`
                    )}
                  >
                    <CardDetailsInfo setActivePanel={setActivePanel} />
                  </div>
                </div>
                <div
                  className={twdClass("pt-2")}                 
                >
                  <WhereClausePanel
                    data={currentBlock}
                    updateElementValue={updateElementValue}
                    columnNames={columnNames}
                  />   
                </div>
                {connectionType === "supabase" && (
                  <div
                    
                    onClick={() => setActivePanel("sortsettings")}
                  >
                    <span className={DC3}>
                      <span className={twdClass("flex-grow bg-white border-2 border-primary-500 py-3 px-4 rounded-full")}>Sort settings</span>
                      <span className={twdClass("py-2 px-3")}>
                        <BtnNext
                          size="sm"
                        /> 
                      </span>
                    </span>
                  </div>
                )}
                <div
                  className={DC6}
                  onClick={() => setActivePanel("generalsettings")}
                >
                  <span className={DC3}>
                    <span className={twdClass("flex-grow bg-white border-2 border-primary-500 py-3 px-4 rounded-full")}>General settings</span>
                    <span className={twdClass("py-2 px-3")}>
                      <BtnNext
                        size="sm"
                      /> 
                    </span>
                  </span>
                </div>
                <div
                  className={DC6}
                  onClick={() => setActivePanel("filtersettings")}
                >
                  <span className={DC3}>
                    <span className={twdClass("flex-grow bg-white border-2 border-primary-500 py-3 px-4 rounded-full")}>Filter and Search settings</span>
                    <span className={twdClass("py-2 px-3")}>
                      <BtnNext
                        size="sm"
                      /> 
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CardDetailsInfo = ({ setActivePanel }) => {
  return (
    <div className={twdClass("px-2 py-2")}>
      <div className={twdClass("text-sm cursor-pointer bg-gray-800 rounded-full")}>
        <div
          className={DC3}
          onClick={() => {
            setActivePanel("designcard");
          }}
        >
          <span className={twdClass("flex-grow bg-white border-2 border-gray-800 py-2 px-4 rounded-full")}>Design/Edit card</span>
          <span className={twdClass("py-1 px-2")}>
            <BtnNext
              size="sm"
            /> 
          </span>
        </div>
      </div>
    </div>
  );
};

const DataSourceSelect = ({ connectionType, setConnectionType }) => {
  return (
    <>
      <select
        className={twdClass(INPUT_TEXT)}
        value={connectionType}
        onChange={(e) => {
          setConnectionType(e.target.value);
        }}
      >
        <option value="">Select</option>
        {CONNECTIONS_METADATA.map((item) => (
          <option value={item.key}>{item.value}</option>
        ))}
      </select>
    </>
  );
};
