import { find, set, replace } from "lodash";
import RenderElements from "../RenderElements";
import { twdClass } from "../../../constants/tailwind.constants";
import { useDispatch } from "react-redux";
import { UPDATE_BLOCK_DATA } from "../../../constants/action.constants";
import { useEffect, useState, Fragment } from "react";

const DetailsElementsProps = ({
  sectionElem,
  styleElementId,
  sectionData,
  blocks,
  listIndex,
}) => {
  const { styleElements } = sectionElem;
  const [isStyleStates, setStyleStates] = useState(false);
  const [elementsProp, setElementProp] = useState([]);
  useEffect(() => {
    let { elements, styleStates=false } = find(styleElements, ["styleElementId", styleElementId]);
    //console.log("styleStates", styleStates)
    elements = elements.map((item) => ({
      ...item,
      updateTo: replace(item.updateTo, "[i]", `[${listIndex}]`),
    }));
    setStyleStates(styleStates)
    setElementProp(elements);
  }, []);

  const { metaDataId } = sectionData;
  const dispatch = useDispatch();

  const updateElementValue = (to, value) => {
    let blockData = set(sectionData, to, value);
    blocks = blocks.map((item) => {
      if (item.metaDataId === metaDataId) {
        return { ...blockData };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks,
    });
  };
  //console.log("elementsProp", isStyleStates, elementsProp)
  return (
    <div className={twdClass("px-3 py-3")}>
      
      {elementsProp.map((element, index) => (
        <Fragment key={index}>
          <div  className={twdClass("mb-1")}>
          <RenderElements            
            element={{...element, styleStates:isStyleStates}}
            data={sectionData}
            updateElementValue={updateElementValue}
          />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default DetailsElementsProps;
