const pxls2 = (type) => {
  const values = [...Array(500)].map((_, index) => {
    return {
      value: index * 2 + "px",
      display: index * 2 + " px",
    };
  });
  return [{ value: "auto", display: "Auto" }, ...values];
};

export const TEXT_DEFAULT_FIELDS = [
  {
    label: "Color",
    inputType: "color",
    name: "color",
    updateTo: "UPDATE_TO.color",
  },
  {
    label: "Font",
    inputType: "fontfamily",
    name: "fontfamily",
    updateTo: "UPDATE_TO.font-family",
  },
  {
    label: "Size",
    inputType: "fontsize",
    name: "fontsize",
    updateTo: "UPDATE_TO.font-size",
  },
  {
    label: "Bold",
    inputType: "fontweight",
    name: "fontweight",
    updateTo: "UPDATE_TO.font-weight",
  },
  {
    inputType: "select",
    label: "Align",
    name: "textalign",
    updateTo: "UPDATE_TO.text-align",
    hideLabel: false,
    options: [
      {
        value: "left",
        display: "Left",
      },
      {
        value: "center",
        display: "Center",
      },
      {
        value: "right",
        display: "Right",
      },
      {
        value: "justify",
        display: "Justify",
      },
    ],
  },
  {
    inputType: "select",
    label: "Display",
    name: "display",
    updateTo: "UPDATE_TO.display",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "Default",
      },      
      {
        value: "inline-block",
        display: "Inline Block",
      },
      {
        value: "block",
        display: "Block",
      },
      {
        value: "flex",
        display: "Flex",
      }
    ],
  },
  {
    inputType: "select",
    label: "Flex Wrap",
    name: "flexwrap",
    updateTo: "UPDATE_TO.flex-wrap",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "Default",
      },
      {
        value: "nowrap",
        display: "No Wrap",
      }, 
      {
        value: "wrap",
        display: "Wrap",
      },      
    ],
  },
  {
    inputType: "select",
    label: "Align Items",
    name: "alignitems",
    updateTo: "UPDATE_TO.alignItems",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "Default",
      },
      {
        value: "center",
        display: "Center",
      },
      {
        value: "flex-start",
        display: "Flex Start",
      },
      {
        value: "flex-end",
        display: "Flex End",
      }
    ],
  },
  {
    inputType: "select",
    label: "Justify Content",
    name: "justifycontent",
    updateTo: "UPDATE_TO.justify-content",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "Default",
      },
      {
        value: "left",
        display: "left",
      },
      {
        value: "center",
        display: "Center",
      },
      {
        value: "right",
        display: "Right",
      },
      {
        value: "space-between",
        display: "Space Between",
      },
      {
        value: "space-around",
        display: "Space Around",
      },      
    ],
  },
  {
    inputType: "select",
    label: "White Space",
    name: "white-space",
    updateTo: "UPDATE_TO.white-space",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "none",
      },
      {
        value: "normal",
        display: "normal",
      },      
      {
        value: "nowrap",
        display: "nowrap",
      },
      {
        value: "pre-wrap",
        display: "pre-wrap",
      },
    ],
  },
  {
    inputType: "selectres",
    label: "Display Order",
    name: "order",
    prop:'order',
    updateTo: "UPDATE_TO.order",
    hideLabel: false,
    options: [
      {
        value: "",
        display: "none",
      },
      {
        value: "1",
        display: "1",
      },      
      {
        value: "2",
        display: "2",
      },
      {
        value: "3",
        display: "3",
      },
      {
        value: "4",
        display: "4",
      },
    ],
  },
  {
    inputType: "color",
    label: "BG Color",
    name: "backgroundcolor",
    updateTo: "UPDATE_TO.background-color",
  },
  {
    inputType: "gradient",
    label: "BG Gradient",
    name: "backgroundgradient",
    updateTo: "UPDATE_TO.background-image",
  },  
  {
    label: "BG Image",
    inputType: "bgimage",
    name: "backgroundimage",
    updateTo: "UPDATE_TO.background-image",
  },
  {
    label: "BG Repeat",
    inputType: "selectres",
    name: "backgroundrepeat",
    prop:'background-repeat',
    updateTo: "UPDATE_TO.background-repeat",
    options: [
      {
        value: "",
        display: "Repeat",
      },
      {
        value: "no-repeat",
        display: "No Repeat",
      },
      {
        value: "repeat-x",
        display: "Repeat X",
      },
      {
        value: "repeat y",
        display: "Repeat Y",
      }      
    ],
  },
  {
    label: "BG Position",
    inputType: "selectres",
    name: "backgroundposition",
    prop:'background-position',
    updateTo: "UPDATE_TO.background-position",
    options: [
      { display: "center", value: "center" },
      { display: "top", value: "top" },
      { display: "bottom", value: "bottom" },
      { display: "left", value: "left" },
      { display: "right", value: "right" },
      { display: "top left", value: "top left" },
      { display: "bottom left", value: "bottom left" },
      { display: "top right", value: "top right" },
      { display: "bottom right", value: "bottom right" }    
    ],
  },
  {
    label: "BG Size",
    inputType: "selectres",
    name: "backgroundsize",
    prop:'background-size',
    updateTo: "UPDATE_TO.background-size",
    options: [
      { display: "auto", value: "auto" },
      { display: "cover", value: "cover" },
      { display: "contain", value: "contain" }  
    ],
  }, 
  {
    label: "Opacity",
    inputType: "selectres",
    name: "opacity",
    prop:'opacity',
    updateTo: "UPDATE_TO.opacity",
    options: [
      {
        value: "0",
        display: "none",
      },
      {
        value: "0.1",
        display: "10",
      },
      {
        value: "0.20",
        display: "20",
      },
      {
        value: "0.3",
        display: "30",
      },
      {
        value: "0.4",
        display: "40",
      },
      {
        value: "0.5",
        display: "50",
      },
      {
        value: "0.6",
        display: "60",
      },
      {
        value: "0.7",
        display: "70",
      },
      {
        value: "0.8",
        display: "80",
      },
      {
        value: "0.9",
        display: "90",
      },
      {
        value: "1",
        display: "100",
      },
    ],
  },  
  {
    inputType: "select",
    label: "Height",
    name: "height",
    updateTo: "UPDATE_TO.height",
    options: [
      {
        value: "1px",
        display: "1px",
      },
      {
        value: "2px",
        display: "2px",
      },
      {
        value: "4px",
        display: "4px",
      },
      {
        value: "10px",
        display: "10px",
      },
      {
        value: "20px",
        display: "20px",
      },
      {
        value: "2rem",
        display: "2x",
      },
      {
        value: "4rem",
        display: "4x",
      },
      {
        value: "10rem",
        display: "10x",
      },
    ],
  },    
  {
    label: "Border",
    inputType: "select",
    name: "borderwidth",
    updateTo: "UPDATE_TO.border-width",
    options: [
      {
        value: "0px",
        display: "No Border",
      },
      {
        value: "1px",
        display: "Border",
      },
      {
        value: "2px",
        display: "Border - 2",
      },
      {
        value: "4px",
        display: "Border - 4",
      },
      {
        value: "8px",
        display: "Border - 8",
      },
      {
        value: "1px 0px 0px 0px",
        display: "Border (T)",
      }, 
      {
        value: "2px 0px 0px 0px",
        display: "Border (T) -2",
      }, 
      {
        value: "0px 1px 0px 0px",
        display: "Border (R)",
      }, 
      {
        value: "0px 2px 0px 0px",
        display: "Border (R) - 2",
      },
      {
        value: "0px 0px 1px 0px",
        display: "Border (B)",
      }, 
      {
        value: "0px 0px 2px 0px",
        display: "Border (B) - 2",
      },
      {
        value: "0px 0px  0px 1px ",
        display: "Border (L)",
      }, 
      {
        value: "0px 0px  0px 2px",
        display: "Border (L) - 2",
      },    
      {
        value: "2px 0px 0px 0px",
        display: "Border (T) - 2",
      }, 
      {
        value: "1px 0px 1px 0px",
        display: "Border (TB)",
      }, 
      {
        value: "2px 0px 2px 0px",
        display: "Border (TB) - 2",
      }, 
      {
        value: "0px 1px 0px 1px",
        display: "Border (LR)",
      },
      {
        value: "0px 2px 0px 2px",
        display: "Border (LR) - 2",
      },        
    ],
  },
  {
    label: "Border",
    inputType: "color",
    name: "bordercolor",
    updateTo: "UPDATE_TO.border-color",
  },
  {
    label: "Radius",
    inputType: "selectres",
    name: "borderradius",
    prop:'border-radius',
    updateTo: "UPDATE_TO.border-radius",
    options: [
      {
        value: "0px",
        display: "none",
      },
      {
        value: "0.125rem",
        display: "xs",
      },
      {
        value: "0.25rem",
        display: "sm",
      },
      {
        value: "0.375rem",
        display: "md",
      },
      {
        value: "0.5rem",
        display: "lg",
      },
      {
        value: "0.75rem",
        display: "xl",
      },
      {
        value: "1rem",
        display: "2xl",
      },
      {
        value: "1.5rem",
        display: "3xl",
      },
      {
        value: "9999px",
        display: "full",
      },
      {
        value: "50%",
        display: "round",
      },
    ],
  },
  {
    label: "Padding",
    inputType: "padding",
    name: "padding",
    updateTo: "UPDATE_TO.padding",
  },
  {
    label: "Margin",
    inputType: "margin",
    name: "margin",
    updateTo: "UPDATE_TO.margin",
  },
  {
    inputType: "selectres",
    label: "Height",
    name: "height2px",
    prop:'height',
    updateTo: "UPDATE_TO.height",
    options: pxls2(),
  },
  {
    inputType: "selectres",
    label: "Width",
    name: "width2px",
    prop:'width',
    updateTo: "UPDATE_TO.width",
    options: pxls2(),
  },
  {
    inputType: "columns",
    label: "Columns",
    name: "columns",
    updateTo: "UPDATE_TO",
  },
  {
    inputType: "select",
    name: "justify-align",
    label: "Align",
    updateTo: "UPDATE_TO.justify-content",
    options: [
      {
        value: "left",
        display: "Left",
      },
      {
        value: "center",
        display: "Center",
      },
      {
        value: "right",
        display: "Right",
      },
    ],
  },
  {
    inputType: "selectres",
    label: "Width",
    name: "width-percent",
    updateTo: "UPDATE_TO.width",
    prop:'width',
    options: [
      {
        value: "none",
        display: "None",
      },
      {
        value: "auto",
        display: "Auto",
      },
      {
        value: "100%",
        display: "Full",
      },
      ...[...Array(20)]
        .map((_, index) => {
          return {
            value: index * 5 + "%",
            display: index * 5 + "%",
          };
        })
        .reverse(),
    ],
  },
  {
    inputType: "select",
    label: "Shadow",
    name: "shadow",
    updateTo: "UPDATE_TO.shadow",
    options: [
      {
        value: "",
        display: "None",
      },
      {
        value: "sm",
        display: "Small",
      },
      {
        value: "md",
        display: "Medium",
      },
      {
        value: "lg",
        display: "Large",
      },
      {
        value: "xl",
        display: "Extra Large",
      },
      {
        value: "2xl",
        display: "2 Extra Large",
      },
    ],
  },
];
