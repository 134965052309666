import { BTN_CLS } from "../../../constants/var.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import FaIcons from "../../common/FaIcons";
import { useDispatch } from "react-redux";
import { deletePageById } from "../../../api/services";
import { TOGGEL_CONFIRM_POPUP, RESET_CONFRIM_POPUP } from "../../../constants/action.constants";
import {Button} from "../../ui/com_ui";

const PG_1 = twdClass("flex items-center mb-2 py-2 px-6");
const PG_5 = twdClass("text-xl py-2 my-2 font-semibold");
const PG_6 = twdClass("text-sm flex bg-gray-100 rounded-full px-2 py-2 items-center");
const PG_7 = twdClass(
  `${BTN_CLS} bg-gray-300 hover:bg-gray-400 mr-2 text-xs font-semibold`
);

const PagesLayoutHeader = ({
  settings,
  page_id,
  getAllPages,
  activePage,
  setActivePage,
}) => {
  const dispatch = useDispatch();
  const onConfirmDelete = () => {
    dispatch({type: RESET_CONFRIM_POPUP});
    if (page_id)
      deletePageById(page_id).then((resp) => {
        if (resp.success) {
          getAllPages(page_id);
        }
      });
  };
  const onDeleteClick = () => {
    dispatch({
      type: TOGGEL_CONFIRM_POPUP,
      data: {
        showConfirmPopup: true,
        confirmLabel:"Are you sure, You want to delete Page ?",
        confrimCallBack: onConfirmDelete,
      },
    });
  };  
  return (
    <div>
      <div className={PG_1}>
        <h1 className={PG_5}>{settings.pageName}</h1>
        <div  className={twdClass("flex-grow")} ></div>     
        <div className={PG_6}>
          {page_id && <Button
            type="default" size="sm" color={activePage === "page" ? 'white' : "transparent" }  className={"whitespace-nowrap"}    
            onClick={() => setActivePage("page")}
          >           
            Page Settings
          </Button>} 
          {page_id && <Button
            type="default"  size="sm" color={activePage === "site" ? 'white' : "transparent" }  className={"whitespace-nowrap"}    
            onClick={() => setActivePage("site")}
          >            
            Page SEO
          </Button>}        
          {page_id && <Button
            type="default"  size="sm" color={activePage === "extjs" ? 'white' : "transparent" }  className={"whitespace-nowrap"}    
            onClick={() => setActivePage("extjs")}
          >           
            External JS
          </Button> }   
          {page_id && <Button
            type="default"  size="sm" color={activePage === "advanced" ? 'white' : "transparent" }  className={"whitespace-nowrap"}    
            onClick={() => setActivePage("advanced")}
          >           
            Advanced
          </Button> }
        </div>
        <Button
          type="icon" iconImg={"trash-04-red.svg"} size="xs" className={"ml-2 px-3 py-3 bg-red-100 text-red-500"} 
          onClick={onDeleteClick}
        >    
        </Button>
      </div>
    </div>
  );
};

export default PagesLayoutHeader;
