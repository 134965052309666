import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { getSheetInfo, getSheetColumns } from "../../../api/services";
import { useState, useEffect } from "react";
import { processParamValues } from "../../../utils/common.utils";
import PagesGoogleParams from "./PagesGoogleParams";
import { cloneDeep, find, merge } from "lodash";
import CustomSelectBox from "../../common/CustomSelectBox";
import { toast } from "react-toastify";
import {Button} from "../../ui/com_ui";

const PagesGoogleSheetInfo = ({
  project_id,
  settingsData,
  pageInfo,
  connections,
  updateSettingsData,
}) => {
  // const [value, setValue] = useState("");
  const [sheetNames, setSheetNames] = useState([]);
  const [sheetColumns, setSheetColumns] = useState([]);
  const [paramsList, setParamsList] = useState([]);
  const { connectionId } = settingsData;

  const fetchSheetNames = () => {
    const {
      connection_details: { spreadsheetId },
    } = find(connections, ["connection_id", connectionId]);

    if (spreadsheetId) {
      getSheetInfo(spreadsheetId).then((resp) => {
        if (resp.success) {
          setSheetNames(resp.data);
        } else {
          toast.error("Error while fetching sheet names");
        }
      });
    }
  };

  const fetchColumnNames = (e) => {
    const {
      connection_details: { spreadsheetId },
    } = find(connections, ["connection_id", connectionId]);

    updateSettingsData("googleSheetName", e.target.value);
    getGoogleSheetColumns({
      sheet_name: e.target.value,
      spreadsheetId,
    });
  };

  const getGoogleSheetColumns = ({ sheet_name, spreadsheetId }) => {
    getSheetColumns({
      project_id,
      sheet_name,
      spreadsheetId,
      connectionId,
      ranges: [`${sheet_name}!A1:Z2`],
    }).then((resp) => {
      if (resp.success) {
        setSheetColumns(resp.data);
      } else {
        toast.error("Error while fetching sheet columns");
      }
    });
  };

  const updateParamsValues = (item) => {
    const params = cloneDeep(settingsData.paramsValues);
    const rec = find(params, ["replaceTo", item.replaceTo]);
    if (rec) {
      merge(rec, item);
    } else {
      params.push(item);
    }
    updateSettingsData("paramsValues", params);
  };

  useEffect(() => {    
    const con=find(connections, ["connection_id", connectionId]);
    if (con && con.connection_details) {
      const {
        connection_details: { spreadsheetId },
      } = con;

      fetchSheetNames(spreadsheetId);
      if (settingsData.googleSheetName) {
        getGoogleSheetColumns({
          sheet_name: settingsData.googleSheetName,
          spreadsheetId: spreadsheetId,
        });
      }
    }
  }, []);

  useEffect(() => {
    const pageString = JSON.stringify(pageInfo);
    const { paramsValues } = settingsData;
    let data = processParamValues(pageString);
    data = data.map((itm) => {
      const val = paramsValues.filter((rc) => rc.replaceTo === itm.key);
      if (val.length > 0) {
        itm.columnValue = val[0].columnValue;
      }
      return itm;
    });
    setParamsList(data);
  }, [pageInfo]);

  const connectionOptions = connections
    .filter((item) => item.connection_type === "googleSheet")
    .map((item) => ({
      key: item.connection_id,
      value: item.connection_details.displayName,
    }));
  const connectValue = find(connectionOptions, ["key", connectionId])?.key;

  return (
    <div>
      <div
        className={twdClass("my-2 py-2 font-2xl font-semibold w-full border-t border-gray-100 ")}
      >
        Google Sheet Info
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Connections</label>
        <div className={twdClass("flex-1")}>
          <CustomSelectBox
            value={connectValue}
            options={connectionOptions}
            updateValue={(val) => {
              updateSettingsData("connectionId", val);
            }}
          />
        </div>
        <Button
          type="default"  color="black" size={"md"} className={'ml-2'}  
          onClick={() => fetchSheetNames()}
        >
          Fetch Sheet
        </Button>
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Sheet Name</label>
        <div className={twdClass("flex-1 mr-2")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={settingsData.googleSheetName}
            onChange={fetchColumnNames}
          >
            <option value="">Select</option>
            {sheetNames.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={twdClass("flex items-start mb-2 border-t border-gray-100 pt-2")}>
        <label className={twdClass("w-40 text-sm")}>Params Map</label>
        <div className={twdClass("flex-1 mr-2 grid grid-cols-2 gap-2")}>
          <div className={twdClass("pt-2")}>
            Slug Param
            <span className={twdClass("ml-2 text-xs text-gray-400")}>
              (Ex: /:id)
            </span>
          </div>
          <div>
            <CustomSelectBox
              value={settingsData.slugParamColumn}
              options={sheetColumns}
              updateValue={(value) => {
                updateSettingsData("slugParamColumn", value);
              }}
            />
          </div>
          {paramsList.map((item) => (
            <PagesGoogleParams
              item={item}
              sheetColumns={sheetColumns}
              updateParamsValues={updateParamsValues}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PagesGoogleSheetInfo;
