import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop } from "@uppy/react";
import { UPLOADTO_S3 } from "../../../api/endpoint.urlconstants";
import { useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import { isEmpty, replace } from "lodash";
import { toast } from "react-toastify";
import FaIcons from "../../common/FaIcons";
import { useParams } from "react-router-dom";
import { decodeLowerCaseId } from "./../../../utils/hashid.utils";
import { useSelector } from "react-redux";
import { bytesToSize } from "../../../utils/common.utils";

const S3Uploader = ({ imageUrl, updateS3Path, maxFileSize = 1000000 }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    projectData: { project_id },
  } = useSelector((state) => state.studioDetails);

  // const maxFileSize = 1000000; //i.e. 1MB
  const allowedFileTypes = ["image/*", "jpg", "jpeg", "png", "gif"];
  useEffect(() => {
    const value = isEmpty(imageUrl) ? null : imageUrl;
    setImage(value);
  }, [imageUrl]);
  const uppy = new Uppy({
    id: "uppyUpload", // use an id if you plan to use multiple Uppys (on different pages etc.)
    autoProceed: true, // if true the file uploads as soon as you drag it into the upload area, or select it - your user cannot edit file name or add metadata or tags - for this reason, we use 'false'
    restrictions: {
      // we can add restrictions here:
      maxFileSize, //i.e. 1MB
      maxNumberOfFiles: 1,
      minNumberOfFiles: null, // if null, no minimum requirement
      allowedFileTypes, // can use an array of extensions, i.e. ['.doc', '.docx', '.xls', '.xlsx']
    },
    onBeforeFileAdded: (currentFile, files) => {
      console.log(currentFile.type);
      const name = currentFile.name.split(".");
      var regex = /^[a-zA-Z 0-9_-]+$/g;
      if (name.length >= 2 && !regex.test(name[0])) {
        toast.error(
          "Skipping file because it has special character in filename"
        );
        return false;
      } else if (currentFile.size > maxFileSize) {
        //const size = maxFileSize === 1000000 ? "1MB" : "10KB";
        toast.error(
          `Skipping file because file size is more than ${bytesToSize(
            maxFileSize
          )}`
        );
        return false;
      } else if (
        allowedFileTypes.indexOf(currentFile.type.split("/")[0]) === -1 &&
        allowedFileTypes.indexOf(currentFile.type.split("/")[1]) === -1
      ) {
        toast.error("Skipping file because trying to upload invalid file");
        return false;
      }
      return true;
    },
    logger: Uppy.debugLogger, // use this for debugging to see what's gone wrong
  });

  uppy.use(AwsS3, {
    fields: [],
    getUploadParameters(file) {
      setLoading(true);
      return fetch(UPLOADTO_S3, {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded", // examples I found via the Uppy site used 'content-type': 'application/json' and did not work
        },
        body: JSON.stringify({
          filename: file.name, // here we are passing data to the server/back end
          id: project_id,
          contentType: file.type,
          metadata: {
            name: file.meta["name"],
          },
        }),
      })
        .then((response) => {
          return response.json(); // return the server's response as a JSON promise
        })
        .then((data) => {
          return {
            method: data.method, // here we send method, url, fields and headers to the AWS S3 bucket
            url: data.url,
            fields: data.fields,
            headers: data.headers,
          };
        });
    },
  });

  const clearImage = () => {
    setImage(null);
    updateS3Path("");
  };

  uppy.on("complete", (result) => {
    //let url = result.successful[0].uploadURL;
    const url = replace(
      result.successful[0].uploadURL,
      "flezr-data.s3.amazonaws.com",
      "d11lx8wl9i3fvs.cloudfront.net"
    );
    // console.log(url);
    setLoading(false);
    setImage(url);
    updateS3Path(url);
  });

  return (
    <div className={`s3-uploader-cls ${twdClass("relative")}`}>
      {loading && (
        <div
          className={twdClass(
            "absolute z-10 inset-0 flex items-center w-full text-xs bg-gray-50 rounded-sm justify-center font-semibold"
          )}
        >
          Uploading...
        </div>
      )}
      {image && (
        <div className={`s3-uploader-img ${twdClass("mb-2")}`}>
          <button
            title="Clear Image"
            className={twdClass(
              "absolute right-1 top-1 rounded-full bg-white hover:bg-gray-100 w-6 h-6"
            )}
            aria-label="Close"
            onClick={clearImage}
          >
            <FaIcons icon="cross" className={twdClass("text-sm")} />
          </button>
          <img src={image} className={twdClass("rounded-md")} alt="" />
        </div>
      )}
      <span className="s3-uploader-drag">
        <DragDrop
          uppy={uppy}
          locale={{
            strings: {
              dropHereOr: "Drop here or %{browse}",
              browse: "browse",
            },
          }}
        />
      </span>
    </div>
  );
};

export default S3Uploader;
