import React, { useState } from "react";
import classNames from "classnames";
import { twdClass } from "../../constants/tailwind.constants";
import { useDetectClickOutside } from "react-detect-click-outside";

const Button = ({ type='default', cType='', onoff=false, color='', iconImg='', size='md', className='', disabled=false, onClick, children }) => {
  let c_=' px-2 py-2 '
  let co_=''
  let cof_=''
  let cis_='w-6'
  let csh_='shadow-md hover:shadow-lg  active:shadow-sm '
  //console.log("className", className)
  if(color=='primary'){
    co_='bg-primary-500'
  }else if(color=='secondary'){
    co_='bg-secondary-500 text-white'
  }else if(color=='white'){
    co_=' bg-white'
  }else if(color=='black'){
    co_='bg-black text-white'
  }else if(color=='transparent'){
    co_=' '
    csh_=' '
  }
  if(type=='default'){
    if(size=='sm'){
      c_='text-sm px-4 py-1'
    }else if(size=='xs'){
      c_='text-xs px-2 py-1'
    }else{
      c_='px-8 py-2'
    }
    if(cType=='iconText' || cType=='icon'){
      if(size=='sm'){     
        cis_='w-5'
      }else if(size=='xs'){     
        cis_='w-4'
      }
    }
    
  }else if(type=='icon' ){
    if(size=='sm'){
      c_='px-1 py-1'
      cis_='w-5'
    }else if(size=='xs'){
      c_='px-1 py-1'
      cis_='w-4'
    }else if(size=='xss'){
      c_='px-1 py-1'
      cis_='w-3'
    }else{
      c_='px-2 py-2'
    }
  }
  if(onoff=='on'){
    cof_='opacity-100'
  }else if(onoff=='off'){
    cof_='opacity-50'
  }
  
  if(cType=='iconText'){
    children=(
      <div  className={twdClass(`flex items-center`)}><div className={twdClass(`${cis_} mr-2`)}><img src={`/assets/images/${iconImg}`}/></div><div>{children}</div></div>
    )
  }else if(cType=='icon'){
    children=(
      <div  className={twdClass(`flex items-center`)}><div className={twdClass(`${cis_}`)}><img src={`/assets/images/${iconImg}`}/></div></div>
    )
  }else if(type=='icon'){
    children=(
      <div className={twdClass(`flex justify-center items-center`)}><img className={twdClass(`${cis_}`)} src={`/assets/images/${iconImg}`}/></div>
    )
  }
  let c__ = classNames({ "opacity-25": disabled, 'transform hover:-translate-y-px active:translate-y-0':!disabled});

  return (
    <div className={twdClass(`${c_} ${c__} ${co_} ${cof_} ${csh_} ${className} rounded-full cursor-pointer select-none`)} onClick={onClick}>
      {children}{}
    </div>
  );
};

const BtnStyle = (props) => {
  let {type}=props
  let p={type:'icon', iconImg:'color-swatch-01.svg', color:'primary', size:'xs',...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnBack = (props) => {
  let {type}=props
  let p={type:'icon', iconImg:'arrow-left.svg', color:'white', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnDelete = ({type, className, ...props}) => {  
  let p={type:'icon', iconImg:'trash-04-red.svg', className:`bg-red-100 ${className}`, size:'xs', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnAdd = ({type, ...props}) => {  
  let p={type:'default', cType:'iconText', iconImg:'plus-01-w.svg', color:'black', size:'sm', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnNext = ({type, ...props}) => {  
  let p={type:'icon', iconImg:'arrow-right.svg', color:'white', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnTop = ({type, ...props}) => {  
  let p={type:'icon', iconImg:'arrow-top-sm.svg', color:'white',  size:'sm', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnBottom = ({type, ...props}) => {  
  let p={type:'icon', iconImg:'arrow-bottom-sm.svg', color:'white',  size:'sm', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnEdit = ({type, ...props}) => {  
  let p={type:'icon', iconImg:'pencil-01.svg', color:'white',  size:'xs', ...props}
  return (
    <Button {...p}></Button>
  );
};
const BtnVS = ({type, children='', ...props}) => {  
  let p={ iconImg:'play-03-w.svg', color:'secondary',  size:'sm', ...props}
  if (children!='') {
    p={...p, type:'default', cType:'iconText', ...props}
  }else{
    p={...p, type:'icon', ...props}
  }
  return (
    <Button {...p}>{children}</Button>
  );
};






export {Button, BtnStyle, BtnBack, BtnDelete, BtnAdd, BtnNext, BtnTop, BtnBottom, BtnEdit, BtnVS}
