import { find, findIndex } from "lodash";
import { getDefaultFields } from "../../jsons/common/common.fields";
import RenderElements from "../form/RenderElements";
import { twdClass } from "../../constants/tailwind.constants";

const PROPS_ITEMS = {
  outer: [    
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: [ 
        "color",
        "backgroundcolor" ,        
        "borderwidth",
        "bordercolor", 
        "borderradius",      
        "textalign", 
        "justifycontent",
        "padding",
        "margin",
        "width-percent",       
      ],
    }),
  ],
  inner: [    
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: [ 
        "color",
        "backgroundcolor" ,        
        "borderwidth",
        "bordercolor", 
        "borderradius",      
        "textalign", 
        "padding",
        "margin",
        "width-percent",
        "order",        
      ],
    }),
  ],
  l2: [
    {
      inputType: "select",
      label: "Align",
      updateTo: "align",
      hideLabel: false,
      options: [
        {
          value: "start",
          display: "Left",
        },
        {
          value: "center",
          display: "Center",
        },
        {
          value: "end",
          display: "Right",
        },
      ],
    },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `imgStyleProps`,
      fields: ["height2px", "borderradius", "padding", "margin"],
    }),
  ],   
};


export const CardLocationStylesPanel = ({
  activeLoc,
  locations,
  updateElementValue,
}) => {



  const activeLocation = find(locations, ["name", activeLoc]);
  const indexOf = findIndex(locations, ["name", activeLoc]);

  const list = PROPS_ITEMS[activeLoc=='outer' ? 'outer':'inner'];

  const updateElmentStylesValues = (key, value) => {
    console.log(indexOf, activeLocation)
    if(indexOf!=-1){
      updateElementValue(`cardLocations[${indexOf}].${key}`, value);
    }else if(locations){
      updateElementValue(`cardLocations[${locations.length}]`, {name:activeLoc, [key]:value});      
    }else{
      updateElementValue(`cardLocations[0]`, {name:activeLoc, [key]:value}); 
    }    
  };

  return (
    <div>
      {list &&
        list.map((item) => (
          <div className={twdClass("mb-1")}>
            <RenderElements
              element={item}
              data={activeLocation}
              updateElementValue={updateElmentStylesValues}
            />
          </div>
        ))}
    </div>
  );
};
